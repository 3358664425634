/*eslint-disable*/
import { useNavigate } from 'react-router-dom'
import splashScreen from '../../../assets/spalsh.svg'
import './splash.css'

const Splash = () => {
  let navigate = useNavigate()
  setTimeout(() => {
    navigate('/login')
  }, 2000) //redirect to login page after 2s
  return (
    <div
      className="splashContainer customFlash"
      style={{ animation: 'customFlashAnimation 2s infinite' }}
    >
      <img src={splashScreen} alt="splashScreen" className="splashimg" />
    </div>
  )
}

export default Splash
