import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './resetpassword.css'
import 'react-toastify/dist/ReactToastify.css'
import { VerifyUser, updateUser } from 'src/views/api/api'
import surgical from '../../../assets/images/surgi-cal blue logo.png'
import PasswordChecklist from 'react-password-checklist'
import CryptoJS from 'crypto-js'
import { CModal, CModalTitle, CModalBody, CModalHeader } from '@coreui/react' // Import CoreUI Modal components
import SuccessIcon from '../../../assets/images/icons/Successfullicon.svg'

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [resetError, setResetError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [showPasswordCheck, setShowPasswordCheck] = useState('')
  const [validPassword, setValidPassword] = useState(true)
  const [user, setUser] = useState({})
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { id, token } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    getUserEmail()
  }, [])

  const getUserEmail = async () => {
    try {
      let res = await VerifyUser(id)
      let userEmail = res.data

      const decryptedPassword = CryptoJS.AES.decrypt(
        userEmail.password,
        process.env.REACT_APP_MyKey,
      ).toString(CryptoJS.enc.Utf8)

      setShowPasswordCheck(decryptedPassword)
      setUser(userEmail)
    } catch (error) {
      console.error('Error fetching user:', error)
    }
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    setShowPasswordChecklist(true)
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setValidPassword(true)
    setPasswordError('')
    setConfirmPasswordError('')

    if (password.trim() === '' || confirmpassword.trim() === '') {
      setConfirmPasswordError('Please enter a password.')
      return
    }

    if (password !== confirmpassword) {
      setConfirmPasswordError('Password does not match.')
      return
    }

    if (password === showPasswordCheck) {
      setConfirmPasswordError('Do not use your previous password.')
      return
    }

    const isPasswordValid = (password) => {
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/
      return passwordRegex.test(password)
    }

    if (!isPasswordValid(password)) {
      setValidPassword(false)
      setPasswordError(
        'Password must contain a capital letter, a digit, and a special character (@#$%^&*!).',
      )
      return
    }

    setLoading(true)

    try {
      const res = await updateUser(user.id, { password, token })

      if (res.status === 200) {
        setSuccessMessage('Password updated successfully!')
        setShowSuccessModal(true)
        setTimeout(() => {
          navigate('/login')
        }, 2000)
      } else if (res.data.message === 'Unauthorized: Token has expired') {
        setResetError('Reset password link is expired, please try again.')
      }
    } catch (error) {
      console.error('Error updating password:', error)
      setResetError('Error updating password. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const handlePasswordValidation = (isValid) => {
    setIsPasswordValid(isValid)
  }

  const handleModalClose = () => {
    setShowSuccessModal(false)
  }

  return (
    <>
      <div className="reset-form-page">
        <div className="reset-form-input-section">
          <form className="reset-password-form" onSubmit={handleSubmit}>
            <div className="reset-form-logo-content">
              <img src={surgical} alt="surgi-cal logo" />
            </div>
            <div className="reset-password-input-content">
              <div className="reset-password-heading-content">
                <div className="reset-text-heading">Reset Password</div>
              </div>
              <label htmlFor="password" className="reset-password-label">
                Enter New Password
              </label>
              <div className="password-input-container">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="reset-password-input"
                  onChange={handlePasswordChange}
                />
              </div>
              {showPassword ? (
                <i
                  className="fa-solid fa-eye email-password-icon "
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              ) : (
                <i
                  className="fa-solid fa-eye-slash email-password-icon"
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              )}
              {confirmPasswordError && <div className="password-error">{confirmPasswordError}</div>}
            </div>

            <div className="confirmpassdiv">
              <div className="reset-password-input-content">
                <label htmlFor="password" className="reset-password-label">
                  Confirm Password
                </label>
                <div className="password-input-container">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="reset-password-input"
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
                {showConfirmPassword ? (
                  <i
                    className="fa-solid fa-eye email-password-icon "
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-eye-slash email-password-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                )}
              </div>
              {showPasswordChecklist && (
                <PasswordChecklist
                  className="password-checklist"
                  rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                  minLength={8}
                  value={password}
                  valueAgain={confirmpassword}
                  messages={{
                    minLength: 'At least 8 characters',
                    specialChar: 'Contains one special character (@,#,$,%,etc.)',
                    number: 'Contains one number',
                    capital: 'Contains one uppercase letter',
                    lowercase: 'Contains one lowercase letter',
                    match: 'Passwords match.',
                    PrevPassword: 'Do not use prev-password',
                  }}
                  iconSize="10"
                  invalidTextColor="red"
                  validTextColor="Green"
                  onChange={handlePasswordValidation}
                />
              )}
            </div>

            <div className="reset-change-btn-content">
              <button
                style={{ opacity: isPasswordValid ? 1 : 0.5 }}
                disabled={!isPasswordValid}
                type="submit"
                className="reset-change-btn"
              >
                {loading ? 'Updating...' : 'Save Password'}
              </button>
              {resetError && <div className="reset-error">{resetError}</div>}
            </div>
          </form>
        </div>
      </div>

      {/* SuccessFull Popup */}
      <CModal alignment="center" visible={showSuccessModal}>
        <CModalBody
          style={{ padding: '10px 10px', position: 'relative' }}
          className="booking-cmodal"
        >
          <CModalHeader
            className="model_title text-center"
            style={{ marginBottom: '10px', padding: '5px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {' '}
              <img src={SuccessIcon} alt="" />{' '}
            </div>
          </CModalHeader>
          <CModalTitle
            className="model_title text-center"
            style={{ fontWeight: '500', color: '#1B1B1F', fontFamily: 'Inter Medium' }}
          >
            Congratulations!
          </CModalTitle>

          <CModalTitle
            className="model_title text-center"
            style={{
              fontWeight: '400',
              color: 'rgba(119, 118, 122, 1)',
              fontSize: '14px',
              marginTop: '10px',
              fontFamily: 'Inter Regular',
              marginBottom: '20px',
            }}
          >
            Your password has been changed successfully.
          </CModalTitle>
        </CModalBody>
      </CModal>
      {/* complete Successful Modal */}
    </>
  )
}

export default ResetPassword
