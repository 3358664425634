import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './scss/style.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Splash from './views/pages/splash/Splash'
import ResetPassword from './views/pages/resetPassword/ResetPassword'
import AssistantResetPassword from './views/pages/resetPassword/AssistantResetPassword.jsx'
import EmailVerification from './views/pages/register/emailVerify/EmailVerify'
import io from 'socket.io-client'
import { Provider } from 'react-redux'
import store from './store'
import AssistantResetPasswordPage from './views/Physician/AcessControl/AssistantResetPassword/AssistantResetPassword'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
const socket = io(process.env.REACT_APP_BACKEND_URL, { transports: ['websocket'] })

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/forgotPassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const AssistantSignUp = React.lazy(() =>
  import('./views/Physician/AcessControl/AssistantSignup/AssistantSignup'),
)

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth()

  if (loading) {
    return (
      <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
      </div>
    )
  }

  return isAuthenticated ? children : <Navigate to="/login" />
}

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" element={<Splash />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password/:id/:token" element={<ResetPassword />} />
            <Route
              exact
              path="/reset-password/:id/:token/:assistantid"
              element={<AssistantResetPassword />}
            />
            <Route exact path="/emailVerify/:id/:token" element={<EmailVerification />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/404" element={<Page404 />} />
            <Route exact path="*" name="Home" element={<DefaultLayout socket={socket} />} />
            <Route exact path="/assistant-register" element={<AssistantSignUp />} />
            <Route
              exact
              path="/assistant-reset-password"
              element={<AssistantResetPasswordPage />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
