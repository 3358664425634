import axios from 'axios'
const url = process.env.REACT_APP_BACKEND_LIVE_URL

// for Credentials  verify to store cookies  in local storage
const jsonconfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
}
// Send Invite to Assistant user
export const sendAssistantInvite = async (data) => {
  try {
    return await axios.post(
      `${url}/send-assistant-user-invite`,

      data,

      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

// Create Assistant user
export const CreateAssistantUser = async (user) => {
  try {
    return await axios.post(`${url}/assistant-users/create`, user)
  } catch (err) {
    console.error('Error creating user:', err)
    throw err
  }
}

// get assistant data by id

export const getAssistantDataById = async (data) => {
  try {
    return await axios.post(`${url}/get-assistant-data`, data)
  } catch (err) {
    console.error('Error creating user:', err)
    throw err
  }
}
// Add Assistant user
export const AddAssistantUser = async (user) => {
  try {
    return await axios.post(`${url}/assistant-users/add`, user)
  } catch (err) {
    console.error('Error creating user:', err)
    throw err
  }
}

export const DeleteAssistantUser = async (user_id, assistant_id) => {
  try {
    return await axios.post(
      `${url}/assistant-users/delete/${user_id}/${assistant_id}`,
      jsonconfig,
    )
  } catch (err) {
    console.error('Error deleting schedule case:', err)
    throw err
  }
}
// get Assistant User by Physician Id
export const GetUserById = async (id) => {
  try {
    return await axios.get(`${url}/assistant-users/${id}`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}

export const updateAssistantUser = async (user_id, Assistant_id, data) => {
  try {
    return await axios.post(
      `${url}/assistant-users/update/${user_id}/${Assistant_id}`,
      data,
      jsonconfig,
    )
  } catch (error) {
    console.error('Error updating assistant user:', error)
    throw error
  }
}

export const updateAssistantUserData = async (user_id, Assistant_id, data) => {
  try {
    if ('password' in data) {
      // Exclude the password field from the userData
      delete data.password
    }

    return await axios.post(
      `${url}/assistant-users/update/${user_id}/${Assistant_id}`,
      data,
      jsonconfig,
    )
  } catch (error) {
    console.error('Error updating assistant user:', error)
    throw error
  }
}

//  Get All Assitant Email
// Function to get all assistant emails
export const getAllAssistantEmails = async () => {
  try {
    return await axios.get(`${url}/assistant-user/emails`, jsonconfig)
  } catch (error) {
    console.error('Error fetching assistant emails:', error)
    throw error
  }
}

export const getAllUserEmails = async () => {
  try {
    return await axios.get(`${url}/assistant-user/emails`, jsonconfig)
  } catch (error) {
    console.error('Error fetching assistant emails:', error)
    throw error
  }
}

export const getAllAssistantByUserRole = async () => {
  try {
    return await axios.get(`${url}/get-assistant-user-by-user`, jsonconfig)
  } catch (error) {
    console.error('Error fetching assistant emails:', error)
    throw error
  }
}

export const sendResetPasswordMail = async (data, userID, matchedAssistantID, userData) => {
  try {
    return await axios.post(
      `${url}/reset-password-sendmail`,
      {
        data,
        userID,
        matchedAssistantID,
        userData,
      },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error reset Password email send:', error)
    throw error
  }
}

export const getAllAssistantByBusinessName = async (name) => {
  try {
    return await axios.post(
      `${url}/get-assistant-business-name`,{business_name:name},
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Get Assistant User:', error)
    throw error
  }
}

