import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './EmailVerify.css'
import { VerifyUser, updateUser } from 'src/views/api/api' // Assuming GetAllUser is imported from the correct path
import loadingGif from '../../../../assets/loader/BlueLoader.gif'
import logo from '../../../../assets/images/surgi-cal blue logo.png'

export default function EmailVerify() {
  const { id, token } = useParams() //Fetching id from URLs
  const [loading, setLoading] = useState(true) //State for handling loading
  const [userData, setUserData] = useState([]) //State for handling user data
  const [verificationMessage, setVerificationMessage] = useState('') //State for handling verification message
  const navigate = useNavigate()

  //Fecthing all user data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await VerifyUser(id)
        const users = res.data
        setUserData(users)
        setLoading(false) // Set loading to false after fetching the data
        // Check if the id exists in the fetched data
        let data = users.id
        // const userExists = users.some((user) => user.id === id);
        if (data === id) {
          //Set verification message
          setVerificationMessage('Verification complete! Please wait for Admin Approval')
          const itemStatus = 1

          //Update user API
          const response = await updateUser(id, { itemStatus, token })
        } else {
          //Set verification failed message
          setVerificationMessage('Verification failed!')
        }
      } catch (error) {
        console.error(error)
      }
    }

    const loadingTimeout = setTimeout(() => {
      fetchData()
    }, 3000) //fetchData function is called after 3s

    return () => clearTimeout(loadingTimeout)
  }, [id]) //useEffect dependecy

  return (
    <div className="verify-email-section">
      <div className="verify-email-container">
        <div className="verify-logo-section">
          <img src={logo} alt="" style={{ width: '200px' }} />
        </div>
        <div className="verify-email-content">
          {loading ? (
            <div className="verificationMessageText" style={{ textAlign: 'center' }}>
              Please wait while we verify your email
              <img src={loadingGif} alt="" style={{ width: '40px', marginLeft: '15px' }} />
            </div>
          ) : (
            <div className="verificationMessageText" style={{ textAlign: 'center' }}>
              {verificationMessage}
            </div>
          )}
          {!loading && (
            <button className="verify-btn" onClick={() => navigate('/login')}>
              Proceed to Login
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
