/* eslint-disable */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './resetpassword.css'
import 'react-toastify/dist/ReactToastify.css'
import { AssistantVerifyUser } from 'src/views/api/api'
import { updateUser, AssistantupdateUser } from 'src/views/api/api'
import surgical from '../../../assets/images/surgi-cal blue logo.png'
import PasswordChecklist from 'react-password-checklist'
import CryptoJS from 'crypto-js'
import { CModal, CModalTitle, CModalBody, CModalHeader } from '@coreui/react' // Import CoreUI Modal components
import SuccessIcon from '../../../assets/images/icons/Successfullicon.svg'

const AssistantResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false) //State for show and hide password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false) //State for show confirm password
  const [resetError, setresetError] = useState('') //State for reset password error message
  const [passwordError, setPasswordError] = useState('Password is not match') //State for password error message
  const [confirmPasswordError, setConfirmPasswordError] = useState('') //State for confirm password error message
  const [password, setPassword] = useState('') //State for handling password
  const [confirmpassword, setConfirmPassword] = useState('') //State for handling confirm password
  const [validPassword, setvalidPassword] = useState(true) //State for handling for valid password
  const [user, setUser] = useState([]) // State for handling user
  const [showPasswordCheck, setShowPasswordCheck] = useState('')
  const { id, token, assistantid } = useParams() // State for fetching id from URLs\
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  let navigate = useNavigate()

  //Fetching all users data
  let getUserEmail = async () => {
    let res = await AssistantVerifyUser(id)
    let userEmail = res?.data[0]
    const decryptedPassword = CryptoJS.AES.decrypt(
      userEmail.password,
      process.env.REACT_APP_MyKey,
    ).toString(CryptoJS.enc.Utf8)

    setShowPasswordCheck(decryptedPassword)
    setUser(userEmail)
  }
  // this function is called when the component is mounted
  useEffect(() => {
    getUserEmail()
  }, [])

  let userId = user?.user_id
  let AssitantUserId = user?.assistant_id

  //function for tracking the changes of password input
  let handlePasswordChange = (e) => {
    //set the value of password input
    setPassword(e.target.value)
    setShowPasswordChecklist(true)
  }
  //function for tracking the changes of confirm password input
  let handleConfirmPasswordChange = (e) => {
    //set the value of confirm password input
    setConfirmPassword(e.target.value)
  }

  //function for submit data
  const handleSubmit = async (e) => {
    //prevent from unnecessary page refresh
    e.preventDefault()
    setvalidPassword(true)
    setPasswordError('')

    // Reset the error message for Confirm Password
    setConfirmPasswordError('')

    // Check if password and confirm password fields are empty
    if (password.trim() === '' || confirmpassword.trim() === '') {
      setConfirmPasswordError('Please enter a password.')
      return
    }

    // Check if password and confirm password match
    if (password !== confirmpassword) {
      setConfirmPasswordError('Password does not match.')
      return
    }
    if (password === showPasswordCheck) {
      setConfirmPasswordError('Please choose a password different from your previous one.')
      return
    }
    //Check password pattern matches
    const isPasswordValid = (password) => {
      //password pattern matching logic
      const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,}$/
      return passwordRegex.test(password)
    }

    // Check if the password meets the required criteria
    if (!isPasswordValid(password)) {
      setvalidPassword(false)
      setPasswordError(
        'Password must contain a capital letter, a digit, and a special character (@#$%^&*!).',
      )
      return
    }
    // Continue with the password update if the token is valid and has not expired
    if (userId !== undefined) {
      let res = await AssistantupdateUser(userId, AssitantUserId, { password, token })
      // Link expire message
      if (res.data.message === 'Unauthorized: Token has expired') {
        setresetError('Reset password link is expired please try again!')
      }
      if (res.status === 200) {
        setSuccessMessage('Password updated successfully!')
        setShowSuccessModal(true)
        setTimeout(() => {
          navigate('/login')
        }, 2000)
      }
    }
  }
  const handlePasswordValidation = (isValid) => {
    setIsPasswordValid(isValid)
  }
  const handleModalClose = () => {
    setShowSuccessModal(false)
  }
  return (
    <>
      <div className="reset-form-page">
        <div className="reset-form-input-section">
          <form className="reset-password-form" onSubmit={handleSubmit}>
            <div className="reset-form-logo-content">
              <img src={surgical} alt="surgi-cal logo" />
            </div>
            <div className="reset-password-input-content">
              {/* Password field */}
              <div className="reset-password-heading-content">
                <div className="reset-text-heading">Reset Password</div>
              </div>
              <label htmlFor="password" className="reset-password-label">
                Enter New Password
              </label>
              <div className="password-input-container">
                <input
                  type={showPassword ? 'text' : 'password'} // Toggle input type
                  className="reset-password-input"
                  onChange={handlePasswordChange}
                />
              </div>
              {showPassword ? (
                <i
                  className="fa-solid fa-eye email-password-icon "
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              ) : (
                <i
                  className="fa-solid fa-eye-slash email-password-icon"
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              )}
              {confirmPasswordError && <div className="password-error">{confirmPasswordError}</div>}
            </div>

            <div className="confirmpassdiv">
              {/* Confirm Password field */}
              <div className="reset-password-input-content">
                <label htmlFor="password" className="reset-password-label">
                  Confirm Password
                </label>
                <div className="password-input-container">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'} // Toggle input type
                    className="reset-password-input"
                    onChange={handleConfirmPasswordChange}
                  />
                </div>
                {showConfirmPassword ? (
                  <i
                    className="fa-solid fa-eye email-password-icon "
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-eye-slash email-password-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                )}
              </div>
              {showPasswordChecklist && (
                <PasswordChecklist
                  className="password-checklist"
                  rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
                  minLength={8}
                  value={password}
                  valueAgain={confirmpassword}
                  messages={{
                    minLength: 'At least 8 characters',
                    specialChar: 'Contains one speacial character (@,#,$,%,etc.)',
                    number: 'Contains one number',
                    capital: 'Contains one uppercase letter',
                    lowercase: 'Contains one lowercase letter',
                    match: 'Passwords match.',
                  }}
                  iconSize="12"
                  invalidTextColor="red"
                  validTextColor="Green"
                  onChange={handlePasswordValidation}
                />
              )}
              <style jsx>{`
                /* Override display: flex for the li elements in the PasswordChecklist component */
                .password-checklist li {
                  display: block !important;
                  /* Use !important to ensure the override */
                  /* Add any additional styles or modifications as needed */
                }
                .password-checklist li span {
                  // padding: 2px 7px !important;
                  font-size: 12px !important;
                  font-family: 'Inter Medium' !important;
                }

                /* You may need to use even more specific selectors depending on the library's styles */
              `}</style>
            </div>
            <div className="reset-change-btn-content">
              <button
                style={{ opacity: isPasswordValid ? 1 : 0.5 }}
                disabled={!isPasswordValid}
                type="submit"
                className="reset-change-btn"
              >
                Save Password
              </button>

              {resetError && <div className="reset-error">{resetError}</div>}
            </div>
          </form>
        </div>
      </div>
      {/* SuccessFull Popup */}
      <CModal alignment="center" visible={showSuccessModal}>
        <CModalBody
          style={{ padding: '10px 10px', position: 'relative' }}
          className="booking-cmodal"
        >
          <CModalHeader
            className="model_title text-center"
            style={{ marginBottom: '10px', padding: '5px' }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              {' '}
              <img src={SuccessIcon} alt="" />{' '}
            </div>
          </CModalHeader>
          <CModalTitle
            className="model_title text-center"
            style={{ fontWeight: '500', color: '#1B1B1F', fontFamily: 'Inter Medium' }}
          >
            Congratulations!
          </CModalTitle>

          <CModalTitle
            className="model_title text-center"
            style={{
              fontWeight: '400',
              color: 'rgba(119, 118, 122, 1)',
              fontSize: '14px',
              marginTop: '10px',
              fontFamily: 'Inter Regular',
              marginBottom: '20px',
            }}
          >
            Your password has been changed successfully.
          </CModalTitle>
        </CModalBody>
      </CModal>
      {/* complete Successful Modal */}
    </>
  )
}

export default AssistantResetPassword
