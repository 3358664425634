import axios from 'axios'
const url = process.env.REACT_APP_BACKEND_LIVE_URL

// for Credentials  verify to store cookies  in local storage
const jsonconfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
}

/* ----------------------------------------------- schedule case apis start -------------------------------------------------------------------*/
//get all schedule cases
export const getAllScheduleCases = async () => {
  try {
    const response = await axios.get(`${url}/scheduleCase`, jsonconfig)
    return response.data
  } catch (error) {
    console.error('Error fetching schedule cases:', error)
    throw error
  }
}

export const checkAuthorizationCookieApi = async () => {
  return await axios.get(`${url}/auth/check`, jsonconfig)
}

export const getAllScheduleCasesByfacilityId = async () => {
  try {
    const response = await axios.get(`${url}/schedule-case-by-facility-id`, jsonconfig)
    return response.data
  } catch (error) {
    console.error('Error fetching schedule cases:', error)
    throw error
  }
}

export const getAllScheduleCasesVendor = async (data) => {
  try {
    const response = await axios.get(`${url}/schedule-case-by-vendor/${data}`, jsonconfig)
    return response.data
  } catch (error) {
    console.error('Error fetching schedule cases:', error)
    throw error
  }
}

export const GetAllScheduleCaseByUserId = async (ID) => {
  try {
    const response = await axios.post(
      `${url}/scheduleCase/getScheduledCaseByUser/${ID}`,
      {},
      jsonconfig,
    )
    return response.data
  } catch (err) {
    console.error('Error to get schedule case:', err)
    throw err
  }
}

export const GetAllProcedureQueue = async () => {
  try {
    const response = await axios.post(`${url}/scheduleCase/get-procedure-queue`, {}, jsonconfig)
    return response.data
  } catch (err) {
    console.error('Error to get schedule case:', err)
    throw err
  }
}

export const getMultipleUserListApi = async (data) => {
  try {
    const response = await axios.post(`${url}/multiple-user-get`, data, jsonconfig)
    return response.data
  } catch (err) {
    console.error('Error to get schedule case:', err)
    throw err
  }
}

export const updateChangeVendorCaseApi = async (data) => {
  try {
    const response = await axios.post(`${url}/scheduleCase/update-vendor-case`, data, jsonconfig)
    return response.data
  } catch (err) {
    console.error('Error to get schedule case:', err)
    throw err
  }
}

//use to delete a schedule case
export const ScheduleCaseDelete = async (user_id, case_id) => {
  try {
    return await axios.delete(`${url}/scheduleCase/delete/${user_id}/${case_id}`, jsonconfig)
  } catch (err) {
    console.error('Error deleting schedule case:', err)
    throw err
  }
}

export const updateOrRoom = async (userId, selectedCaseId, data) => {
  try {
    return await axios.post(
      `${url}/scheduleCase/update-or-room/${userId}/${selectedCaseId}`,
      data,
      jsonconfig,
    )
  } catch (err) {
    console.error('Error deleting schedule case:', err)
    throw err
  }
}

//get a schedule case by id
export const getScheduleCaseById = async (user_id, case_id) => {
  try {
    const response = await axios.get(
      `${url}/scheduleCase/getScheduledCaseByCaseID/${user_id}/${case_id}`,
      {},
      jsonconfig,
    )

    return response.data
  } catch (error) {
    console.error('Error fetching schedule case by ID:', error)
    throw error
  }
}

//update a schedule case
export const updateScheduleCase = async (user_id, case_id, data) => {
  try {
    return await axios.post(
      `${url}/scheduleCase/editfields/${user_id}/${case_id}`,
      data,
      jsonconfig,
    )
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}
export const updateScheduledCaseFields = async (user_id, case_id, data) => {
  try {
    return await axios.post(`${url}/scheduleCase/update/${user_id}/${case_id}`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}

export const updateScheduleCaseStatus = async (data) => {
  try {
    return await axios.post(`${url}/scheduleCase/accept-case`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}
export const updateScheduleCaseVendorStatus = async (data) => {
  try {
    return await axios.post(`${url}/scheduleCase/vendor-accept-case`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}
export const vendorReleaseTime = async (data) => {
  try {
    return await axios.post(`${url}/release/vendor-accept-case`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}

export const checkStatusVendorAndPhysician = async (data) => {
  try {
    return await axios.post(`${url}/checkstatus/vendor/physician`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}

//add a new schedule case
export const addScheduleCase = async (data) => {
  try {
    return await axios.post(`${url}/scheduleCase/create`, data, jsonconfig)
  } catch (error) {
    console.error('Error creating schedule case:', error)
    throw error
  }
}

/* ----------------------------------------------- schedule case apis end -------------------------------------------------------------------*/

/* ----------------------------------------------- users case apis start -------------------------------------------------------------------*/

//login a user
export const LoginUser = async (data) => {
  try {
    const response = await axios.post(`${url}/login`, data, jsonconfig)
    return response
  } catch (error) {
    console.error(error, 'err')
    throw error
  }
}

export const SendOtp = async (data) => {
  try {
    const response = await axios.post(`${url}/send-otp`, data)
    return response
  } catch (error) {
    console.error(error, 'err')
    throw error
  }
}
export const SendContactVerify = async (data) => {
  try {
    const response = await axios.post(`${url}/send-contact-verify`, data)
    return response
  } catch (error) {
    console.error(error, 'err')
    throw error
  }
}

export const ValidateOtp = async (data) => {
  try {
    const response = await axios.post(`${url}/otp-validation`, data)
    return response
  } catch (error) {
    console.error(error, 'err')
    throw error
  }
}
export const ValidateContactNo = async (data) => {
  try {
    const response = await axios.post(`${url}/contactno-validation`, data)
    return response
  } catch (error) {
    console.error('Error validating contact number:', error)
    throw error
  }
}

//sign up a user
export const UserSignUp = async (user) => {
  try {
    return await axios.post(`${url}/users/create`, user)
  } catch (err) {
    console.error('Error creating user:', err)
    throw err
  }
}

//get's all user's
export const GetAllUser = async () => {
  try {
    return await axios.get(`${url}/users`, jsonconfig)
  } catch (err) {
    console.error('Error fetching users:', err)
    throw err
  }
}

// **********facilityNonBlockTime**********

// export const facilityNonBlockTime = async () => {
//   try {
//     return await axios.get(`${url}/get-facility-non-block-time`, jsonconfig)
//   } catch (error) {
//     console.error(error)
//   }
// }

// ****get login user data ******
export const loginUser = async (id) => {
  try {
    return await axios.post(`${url}/get-login-user?id=${id}`, jsonconfig)
  } catch (error) {
    console.error('Error fetching users:', error)
    throw error
  }
}

export const getEmailNotificationStatus = async (id) => {
  try {
    return await axios.get(`${url}/get-user-status?id=${id}`, jsonconfig)
  } catch (error) {
    console.error('Error fetching users:', error)
    throw error
  }
}

export const signOut = async () => {
  try {
    return await axios.get(`${url}/sign-out`, jsonconfig)
  } catch (err) {
    console.error('Error signing out:', err)
    throw err
  }
}

//get's  user by id
export const getAllFacilityApi = async () => {
  try {
    return await axios.get(`${url}/get-all-facility`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}

export const getAllVendorApi = async () => {
  try {
    return await axios.get(`${url}/get-all-vendor`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}
export const getAllPhysicianApi = async () => {
  try {
    return await axios.get(`${url}/get-all-physician`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}

export const GetUserById = async (id) => {
  try {
    return await axios.get(`${url}/users/${id}`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}
export const getPhysicianDataForFacility = async (id) => {
  try {
    return await axios.post(`${url}/get-physician-data-for-facility`, { id }, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}
export const GetFacilityDetailsById = async (id) => {
  try {
    return await axios.get(`${url}/facility/users/details/${id}`, jsonconfig)
  } catch (error) {
    console.error('Error get Facility users:', error)
    throw error
  }
}
export const GetUserByEmail = async (email) => {
  try {
    return await axios.get(`${url}/users/forgot-password/${email}`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by Email:', err)
    throw err
  }
}
export const VerifyUser = async (id) => {
  try {
    return await axios.get(`${url}/users/verify-email/${id}`, jsonconfig)
  } catch (err) {
    console.error('Error to verify user email:', err)
    throw err
  }
}
export const AssistantVerifyUser = async (id) => {
  try {
    return await axios.get(`${url}/assistantusers/verify-email/${id}`, jsonconfig)
  } catch (err) {
    console.error('Error to verify user email:', err)
    throw err
  }
}

//get's  CheckUserForClearCookie by id
export const CheckUserForClearCookie = async (id) => {
  try {
    return await axios.get(`${url}/check/user-for-cookie-clear/${id}`, jsonconfig)
  } catch (err) {
    console.error('Error fetching user by ID:', err)
    throw err
  }
}

//updates a user
export const updateUser = async (id, data) => {
  try {
    return await axios.post(`${url}/users/edit/${id}`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating users:', error)
    throw error
  }
}
export const deleteUser = async (id, assistantIds) => {
  try {
    return await axios.post(`${url}/user/delete/${id}`,assistantIds, jsonconfig)
  } catch (error) {
    console.error('Error deleting users:', error)
    throw error
  }
}

export const AssistantupdateUser = async (user_id, assistant_id, data) => {
  try {
    return await axios.post(
      `${url}/assistantusers/edit/${user_id}/${assistant_id}`,
      data,
      jsonconfig,
    )
  } catch (error) {
    console.error('Error updating users:', error)
    throw error
  }
}

export const updateFacilityPrivileges = async (id, data) => {
  try {
    return await axios.post(`${url}/update-facility-privileges/${id}`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating users:', error)
    throw error
  }
}

export const addPhysicianId = async (id, data) => {
  try {
    return await axios.post(`${url}/add-physician/${id}`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating users:', error)
    throw error
  }
}

/* ----------------------------------------------- users right apis start -------------------------------------------------------------------*/

//get permission of user
export const getPermission = async (userId) => {
  try {
    return await axios.post(`${url}/userPermission`, { userId }, jsonconfig)
  } catch (error) {
    console.error('Error fetching user permission by ID:', error)
    throw error
  }
}

// Update a users right
export const updateUserPermissions = async (id, data) => {
  try {
    return await axios.post(`${url}/userRight/${id}`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating user rights', error)
    throw error
  }
}
/* ----------------------------------------------- users right apis End -------------------------------------------------------------------*/

/* ----------------------------------------------- Integration Switch apis start -------------------------------------------------------------------*/

// Update getAllIntegrationData status of user
export const updateIntegrationDataStatus = async (id, status) => {
  try {
    return await axios.patch(`${url}/updateIntegrationDataStatus/${id}`, { status }, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}

/* ----------------------------------------------- Integration Switch apis End -------------------------------------------------------------------*/

/* ----------------------------------------------- Patient data apis start -------------------------------------------------------------------*/

// get getAllIntegrationData of user
export const deleteAllPatient = async () => {
  try {
    return await axios.get(`${url}/deleteAllPatient`, jsonconfig)
  } catch (err) {
    console.error('delete all patient:', err)
    throw err
  }
}

/* ----------------------------------------------- Patient data apis End -------------------------------------------------------------------*/

/* ----------------------------------------------- Athena DATA apis Start -------------------------------------------------------------------*/

//get token from athena api
export const getToken = async (id) => {
  try {
    // Fetch clientId and clientSecret from your database
    const response = await axios.get(`${url}/users/${id}`, jsonconfig)

    const { clientId, clientSecret } = response.data.athena

    // Make a request to get the Athena access token
    const accessTokenResponse = await axios.get(
      `${url}/getAthenaToken`,

      {
        params: { clientId: clientId, clientSecret: clientSecret },
      },
      jsonconfig,
    )
    return accessTokenResponse
  } catch (err) {
    throw err
  }
}

export const checkAthenaCredentail = async (practiceid, userId) => {
  try {
    // Make a request to get the Athena access token
    const accessTokenResponse = await axios.get(
      `${url}/getAthenaIntegrationDataAccessToken`,

      {
        params: { practiceid, userId },
      },
      jsonconfig,
    )

    return accessTokenResponse
  } catch (err) {
    throw err
  }
}
export const checkModmedCredentail = async (trimmedBaseUrlData, userId) => {
  try {
    // Make a request to get the Athena access token
    const accessTokenResponse = await axios.get(
      `${url}/modmed-integration`,
      {
        params: { trimmedBaseUrlData, userId },
      },
      jsonconfig,
    )

    return accessTokenResponse
  } catch (err) {
    throw err
  }
}

//get patient data from athena
export const getPatientData = async (data, id) => {
  try {
    const response = await axios.get(`${url}/users/${id}`, jsonconfig)

    const { clientId, clientSecret } = response.data.athena
    return await axios.post(
      `${url}/getAthenaData`,
      data,
      {
        params: { clientId: clientId, clientSecret: clientSecret },
      },
      {
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
      },
      jsonconfig,
    )
  } catch (err) {
    throw err.response.data
  }
}

export const getEpicPatientData = async (patientId) => {
  try {
    const response = await axios.post(`${url}/epic/patient-data?patients=${patientId}`,{}, jsonconfig)
    return response.data
  } catch (err) {
    throw err.response.data
  }
}

export const updateAthenaCredentials = async (clientId, clientSecret) => {
  try {
    // Make a PUT request to your backend API endpoint for updating Athena credentials
    await axios.put(`${url}/updateAthenaCredentials`, { clientId, clientSecret }, jsonconfig)
  } catch (error) {
    throw error
  }
}

/* -----------------------------------------------  Request Token apis End -------------------------------------------------------------------*/

/* ----------------------------------------------- calendar DATA apis Start -------------------------------------------------------------------*/

export const vendorGetCalendarEvent = async (id) => {
  try {
    const response = await axios.get(`${url}/vendorcalendar/get-events/${id}`, jsonconfig)

    return response.data
  } catch (error) {
    console.error('Error fetching schedule case by ID:', error)
    throw error
  }
}

//get calendar events by userID
export const UpdatedSiteOfProcedure = async (data) => {
  try {
    const response = await axios.post(`${url}/update-new-site-procedure`, data, jsonconfig)

    return response.data
  } catch (error) {
    console.error('Error fetching schedule case by ID:', error)
    throw error
  }
}

/* ----------------------------------------------- calendar DATA apis end -------------------------------------------------------------------*/

/* ----------------------------------------------- Notification apis start -------------------------------------------------------------------*/

export const createNotificationCase = async (data) => {
  try {
    return await axios.post(`${url}/notification/create`, data, jsonconfig)
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error // Re-throw the error for the component to handle
  }
}

// get physciain Notification

export const getAllPhysicianNotification = async () => {
  try {
    return await axios.get(`${url}/physician/getNotification`, jsonconfig)
  } catch (error) {
    console.error('Error get users:', error)
    throw error // Re-throw the error for the component to handle
  }
}
export const getAllFacilityNotification = async () => {
  try {
    return await axios.get(`${url}/physician/getFacilityNotification`, jsonconfig)
  } catch (error) {
    console.error('Error get users:', error)
    throw error // Re-throw the error for the component to handle
  }
}

export const markAllRead = async () => {
  try {
    return await axios.post(`${url}/notification/mark-all-read`, {}, jsonconfig)
  } catch (error) {
    console.error('Error updating notification status', error)
    throw error // Re-throw the error for the component to handle
  }
}

export const updateStatusNotifications = async (receiverId, notificationId) => {
  try {
    return await axios.post(`${url}/notification/read`, { receiverId, notificationId }, jsonconfig)
  } catch (error) {
    console.error('Error updating notification status', error)
    throw error
  }
}

export const NotificationPreference = async (data) => {
  try {
    return await axios.post(`${url}/updateNotificationPreferences`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating users:', error)
    throw error // Re-throw the error for the component to handle
  }
}
/* ----------------------------------------------- Notification apis end -------------------------------------------------------------------*/
export const sendEmailtoFacility = async ({ facilityEmail, patientData, notificationData }) => {
  try {
    return await axios.post(
      `${url}/sendEmailFacility`,
      {
        facilityEmail,
        patientData,
        notificationData,
      },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}
export const sendMailToFacilityCancelTheCase = async (data) => {
  try {
    return await axios.post(`${url}/send-mail-to-facility-cancel-case`, data, jsonconfig)
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

// send emial all api are below
export const sendUserInvite = async (facilityEmail, physicianName, physicianEmail) => {
  try {
    return await axios.post(
      `${url}/sendUserInvite`,
      {
        facilityEmail,
        physicianName,
        physicianEmail,
      },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

//to send reset password mail to user
export const sendMail = async (user) => {
  try {
    return await axios.get(`${url}/sendmail?user=${user}`, jsonconfig)
  } catch (err) {
    console.error('Error in sendmail in react server', err)
    throw err
  }
}

//send's verify mail to user
export const sendMailToRegister = async (user) => {
  try {
    return await axios.get(`${url}/sendMailToRegister?user=${user}`, jsonconfig)
  } catch (err) {
    console.error('Error in sendmail to register in react server', err)
    throw err.response
  }
}
// Send mail to Admin while user is register a account
export const sendMailToAdmin = async (userData) => {
  try {
    return await axios.post(`${url}/sendMailToAdmin`, userData, jsonconfig)
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

export const sehduleCaseFacilityInvite = async (facilityEmail, formattedData) => {
  try {
    return await axios.post(
      `${url}/send-schedule-time-Facility-Invite`,
      { facilityEmail, formattedData },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}
export const sendMailToFacilityByVendor = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-facility-privilege-req-byVendor`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailTofacilityForRequest = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-facility-privilege-req`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailTofacilityForBlockTimeRequest = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-block-time-facility-privilege-req`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailToFacilityBySubVendor = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-facility-privilege-req-by-subVendor`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}

export const sendMailToVendorWhenFacilityDecline = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-facility-privilege-declined-byFacility`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailToPhysicianWhenFacilityApproval = async (data) => {
  try {
    return await axios.post(`${url}/approval-facility-physician-privilege`, data, jsonconfig)
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailToPhysicianWhenFacilityReject = async (data) => {
  try {
    return await axios.post(`${url}/rejected-facility-physician-privilege`, data, jsonconfig)
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailToSubVendorWhenFacilityDecline = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-sub-vendor-facility-privilege-declined-byFacility`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailToVendorByFacilityAccept = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-vendor-facility-privilege-accept-byFacility`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}
export const sendMailToSubVendorByFacilityAccept = async (facilityEmail, data) => {
  try {
    return await axios.post(
      `${url}/send-sub-vendor-facility-privilege-accept-byFacility`,
      { facilityEmail, data },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error Send Mail:', error)
    throw error
  }
}

export const sehduleCaseVendorInvite = async (vendorEmail, acceptData) => {
  try {
    return await axios.post(
      `${url}/send-schedule-time-Vendor-Invite`,
      {
        vendorEmail,
        acceptData,
      },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

export const schduleCaseDelineFacility = async () => {
  try {
    return await axios.post(`${url}/decline-schedule-case`, jsonconfig)
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

// --------------------------------- mail-api-end--------------------------------------->

// get current vendor data
export const getCurrentVendorData = async (email) => {
  try {
    return await axios.get(
      `${url}/get-current-vendordata/${email}`,

      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}
export const getCurrentAssistantVendorData = async (email) => {
  try {
    return await axios.get(
      `${url}/get-current-sub-vendordata/${email}`,

      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

export const deleteOpenBlockTimeApi = async (userId, data) => {
  try {
    return await axios.post(
      `${url}/open-block-time/delete-open-block-time/${userId}`,
      data,
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

export const openBlockTimeUpdateApi = async (userId, newData, oldData) => {
  const data = {
    newData: newData,
    oldData: oldData,
  }
  try {
    return await axios.post(
      `${url}/open-block-time/update-open-block-time/${userId}`,
      data,
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

// export const getAllScheduleCasesByfacilityId = async () => {
//   try {
//     const response = await axios.get(`${url}/schedule-case-by-id`, jsonconfig)
//     return response.data
//   } catch (error) {
//     console.error('Error fetching schedule cases:', error)
//     throw error
//   }
// }

// export const getAllScheduleCasesVendor = async () => {
//   try {
//     const response = await axios.get(`${url}/schedule-case-by-vendor`, jsonconfig)
//     return response.data
//   } catch (error) {
//     console.error('Error fetching schedule cases:', error)
//     throw error
//   }
// }
export const sendCancelledBlockMailToFacilityController = async (facilityEmail, formattedData) => {

  try {
    return await axios.post(
      `${url}/send-cancelled-time-information`,
      { facilityEmail, formattedData },
      jsonconfig,
    )
  } catch (error) {
    console.error('Error creating create-event:', error)
    throw error
  }
}

export const getAssociateVendors = async (data) => {
  try {
    const response = await axios.post(`${url}/get-vendors-for-case-booking`, data, jsonconfig)
    return response.data
  } catch (error) {
    console.error('Error fetching schedule cases:', error)
    throw error
  }
}
export const ActionfacilityToVendor = async (maindata) => {
  try {
    const response = await axios.post(`${url}/action-facility-to-vendor`, maindata, jsonconfig)
    return response.data
  } catch (error) {
    console.error('Error fetching schedule cases:', error)
    throw error
  }
}
export const ActionfacilityToSubVendor = async (maindata) => {
  try {
    const response = await axios.post(
      `${url}/action-facility-assistant-vendor`,
      maindata,
      jsonconfig,
    )
    return response.data
  } catch (error) {
    console.error('Error fetching schedule cases:', error)
    throw error
  }
}

export const canceledCase = async (data) => {
  try {
    return await axios.post(`${url}/scheduleCase/canceled-case`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}

export const businessDataApi = async () => {
  try {
    return await axios.get(`${url}/business/name`)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}
export const getNPIdata = async (data) => {
  try {
    return await axios.post(`${url}/general/npi-number`, data)
  } catch (error) {
    console.error('Error fetching NPI data:', error)
    throw error
  }
}

// Fetch NPI data Suggestion using Business name
export const fetchNPI2Suggestions = async (term) => {
  try {
    const response = await fetch(
      `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search?terms=${encodeURIComponent(term)}`,
    )
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }

    const data = await response.json()
    return data[3] // The API returns an array; the second element contains the organization names
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
    return []
  }
}
export const fetchNPI1Suggestions = async (term) => {
  try {
    const response = await fetch(
      `https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search?terms=${encodeURIComponent(term)}`,
    )
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }

    const data = await response.json()
    return data[3] // The API returns an array; the second element contains the organization names
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error)
    return []
  }
}

export const deleteGoogleEvent = async (data) => {
  try {
    return await axios.post(`${url}/scheduleCase/delete-google-event?id=${data}`, {}, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}

export const updateGoogleEvent = async (data) => {
  try {
    return await axios.post(`${url}/scheduleCase/update-google-event`, data, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}


/* ----------------------------------------------- Chatbot -------------------------------------------------------------------*/

export const chatbotsendmessage = async (data) => {
  try {
    return await axios.post(`${url}/chatbot`, data, jsonconfig)
  } catch (error) {
    console.error('Error in chatbot response', error)
    throw error
  }
}

export const logoutEpic = async () => {
  try {
    return await axios.post(`${url}/epic/logout-user`, {}, jsonconfig)
  } catch (error) {
    console.error('Error updating schedule case:', error)
    throw error
  }
}