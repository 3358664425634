import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './CSS/global.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './views/Physician/Calendar/AuthConfig'
// import { GoogleOAuthProvider } from '@react-oauth/google'
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const msalInstance = new PublicClientApplication(msalConfig)
createRoot(document.getElementById('root')).render(
  // <GoogleOAuthProvider clientId={clientId}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  // </GoogleOAuthProvider>,
)
reportWebVitals()
