import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { updateAssistantUser, GetUserById } from 'src/views/api/AssistanceUserAPI'
import '../../../pages/login/Login.css'
import '../../../pages/register/Register.css'
import logo from '../../../../assets/images/surgi-cal blue logo.png'
import PasswordChecklist from 'react-password-checklist'

const AssistantResetPasswordPage = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [assistantDetails, setAssistantDetails] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showPasswordChecklist, setShowPasswordChecklist] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  // Parse query parameters from the URL
  const { userID, assistantID } = queryString.parse(location.search)

  useEffect(() => {
    fetchAssistantDetails()
  }, [])

  //toggle password eye btn
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  //toggle confirm password eye btn
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const fetchAssistantDetails = async () => {
    try {
      const response = await GetUserById(userID)
      let Assistantdata = response.data.Items
      const matchedAssistant = Assistantdata.find(
        (assistant) => assistant.assistant_id === assistantID,
      )
      if (!matchedAssistant) {
        setError('Assistant data not found for the provided email.')
        return
      }

      setAssistantDetails(matchedAssistant) // Update state with fetched details
    } catch (error) {
      console.error('Error fetching assistant details:', error)
      // Handle error, show error message, etc.
    }
  }

  const handleInputChange = (e, field) => {
    if (field === 'password') {
      setPassword(e.target.value)
      const isValidPassword =
        e.target.value?.length >= 8 &&
        /[A-Z]/.test(newPassword) &&
        /[a-z]/.test(newPassword) &&
        /\d/.test(newPassword) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(newPassword)
      setShowPasswordChecklist(!isValidPassword)
    } else if (field === 'confirmPassword') {
      setConfirmPassword(e.target.value)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setError('')

    if (password.trim() === '') {
      setError('Password cannot be empty.')
      setIsLoading(false)
      return
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.')
      setIsLoading(false)
      return
    }

    try {
      // Update password using API
      await updateAssistantUser(userID, assistantID, { password, user_status: 1 })
      navigate('/login')
    } catch (error) {
      console.error('Error updating password:', error)
      setError('Error updating password. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="login-form-page">
      <div className="login-form">
        {/* <h2>Assistant Register Page</h2> */}
        <div className="login-form-logo-container">
          <img src={logo} style={{ width: 'auto' }} alt="" />
        </div>
        <div className="login-heading-content">
          <div className="login-text">Reset Password</div>
        </div>

        {assistantDetails && (
          <div className="login-form-input-section">
            <div className="input-div" style={{ width: '90%' }}>
              <label className="input-pass-label">
                Enter New Password<span className="required-star">*</span>
              </label>
              <div className="password-input-container" style={{ position: 'relative' }}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="login-email-input"
                  style={{ width: '100%' }}
                  value={password}
                  onChange={(e) => handleInputChange(e, 'password')}
                  autoComplete="new-password"
                />
                <span className="password-toggle" onClick={toggleShowPassword}>
                  {showPassword ? (
                    <i
                      className="fa-solid fa-eye email-password-icon "
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-eye-slash email-password-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    ></i>
                  )}
                </span>
                {showPasswordChecklist && (
                  <PasswordChecklist
                    className="password-checklist"
                    style={{ color: 'white', display: 'block' }}
                    rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
                    minLength={8}
                    value={password}
                    valueAgain={password}
                    messages={{
                      minLength: 'At least 8 characters',
                      specialChar: 'Contains one special character (@,#,$,%,etc.)',
                      number: 'Contains one number',
                      capital: 'Contains one uppercase letter',
                      lowercase: 'Contains one lowercase letter',
                    }}
                    iconSize="12"
                    invalidTextColor="red"
                    validTextColor="green"
                  />
                )}
                <style jsx>{`
                  /* Override display: flex for the li elements in the PasswordChecklist component */
                  .password-checklist li {
                    display: block !important;
                    /* Use !important to ensure the override */
                    /* Add any additional styles or modifications as needed */
                  }
                  .password-checklist li span {
                    // padding: 2px 7px !important;
                    font-size: 12px !important;
                    font-family: 'Inter Medium' !important;
                  }

                  /* You may need to use even more specific selectors depending on the library's styles */
                `}</style>
              </div>
            </div>

            <div className="input-div" style={{ width: '90%' }}>
              <label className="input-pass-label">
                Confirm Password<span className="required-star">*</span>
              </label>
              <div className="password-input-container" style={{ position: 'relative' }}>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="login-email-input"
                  style={{ width: '100%' }}
                  value={confirmPassword}
                  onChange={(e) => handleInputChange(e, 'confirmPassword')}
                  autoComplete="new-password"
                />
                <span className="password-toggle" onClick={toggleShowConfirmPassword}>
                  {showConfirmPassword ? (
                    <i
                      className="fa-solid fa-eye email-password-icon "
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-eye-slash email-password-icon"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    ></i>
                  )}
                </span>
              </div>
              {error && <div className="error-message-pasword">{error}</div>}
            </div>
          </div>
        )}
        <div className="login-btn-content">
          <button type="submit" className="login-btn" onClick={handleSubmit}>
            {!loading ? (
              <> {isLoading ? 'Saving...' : 'Save Password'}</>
            ) : (
              <div>
                <Loader />
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AssistantResetPasswordPage
